import tw from 'twin.macro'
import React from 'react'
import { head, get, map, concat } from 'lodash'
import { motion } from 'framer-motion'

import { mooncakeSets } from '../../../../utils/mooncakes'

import '../../../../styles/staticImageInlineStyles.css'
import { currencyFormatter } from '../../../../utils/utils'
import useOrderItems from '../../../../hooks/useOrderItems'
import { StaticImage } from 'gatsby-plugin-image'
import { buttonVariants } from '../../../../utils/motion'

const SubBg = (
  <StaticImage
    src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-btt/mooncake-group/groupSubBg.png"
    alt="breakline"
    style={{
      position: 'absolute',
      zIndex: 0,
      objectFit: 'contain',
      top: 0,
      left: 0,
      right: 0,
      // height: 'auto',
      // aspectRatio: 'auto',
      // display: 'block',
      // bottom: 0,
    }}
  />
)
export default function SetsSection() {
  return (
    <div
      id="mooncake-set"
      css={[
        tw`lg:container mx-auto lg:max-w-screen-lg! px-0.5 xs:px-2 sm:px-6 md:px-8 lg:px-0 relative overflow-hidden! rounded-md`,
      ]}
    >
      <div tw="relative px-2 w-[98%] mx-auto sm:w-[95%] xl:w-full bg-sc_yellow-200">
        {SubBg}
      </div>
      <CakeSets />
      <div tw="bg-savor-cake-primary-green-avocado-300 w-full h-2 sm:hidden"></div>
    </div>
  )
}

function CakeSets() {
  return (
    <div
      className="full-static-image-deep"
      tw="text-savor-cake-primary-green-avocado-200 font-barlow-sans-serif
      flex flex-col gap-6 md:gap-12 relative z-50 justify-center items-center py-10 px-4 rounded"
    >
      {map(mooncakeSets, (cakeSet, index) => {
        const bundledNum = get(cakeSet, 'bundledImages.length')
        const under4 = bundledNum < 4
        const images = concat([cakeSet.image], cakeSet.images || [])
        return (
          <div key={index} tw="flex flex-col items-center">
            <StaticImage
              src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-btt/mooncake-group/line.png"
              alt="breakline"
            />
            <h3 tw="font-barlow-sans-serif uppercase text-center text-sc_red-100 font-bold text-2xl xs:text-3xl sm:text-3xl md:text-4xl lg:text-5xl my-5">
              {cakeSet.showUpName}
            </h3>
            <div
              key={index}
              css={[
                tw`flex items-center w-full gap-4 sm:px-10`,
                under4 ? tw`flex-row justify-between` : tw`flex-col`,
              ]}
            >
              {/* set images */}
              <div
                key={index}
                css={[
                  tw`flex gap-2 sm:gap-5 justify-center items-center w-full`,
                  under4 ? tw`w-1/2` : '',
                ]}
                style={{ flexBasis: '50%' }}
              >
                {images.map((image, index) => (
                  <div
                    tw="sm:max-w-[430px] mx-auto"
                    css={[!under4 ? tw`w-1/2 sm:w-full` : '']}
                    key={index}
                  >
                    {image}
                  </div>
                ))}
              </div>
              {/* cake images && info blocks */}
              <div
                css={[
                  tw`h-full flex flex-col justify-between items-center gap-3 sm:gap-6 md:gap-8 lg:gap-10`,
                  under4 ? tw`w-1/2` : '',
                ]}
              >
                <div
                  tw="flex justify-between items-stretch gap-2 sm:gap-4"
                  css={[bundledNum > 4 ? tw`flex flex-wrap` : '']}
                >
                  {cakeSet.bundledImages &&
                    cakeSet.bundledImages.map((image, index) => (
                      <div key={index} css={[bundledNum > 4 ? tw`w-1/4` : '']}>
                        {image}
                      </div>
                    ))}
                </div>
                <TagAndButton cakeSet={cakeSet} under4={under4} />
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

function TagAndButton({ cakeSet, under4 }) {
  const selectedVariant = head(cakeSet.variants) || {}

  const { addToCartAfterAddons } = useOrderItems()

  const handleAddToCart = () => {
    addToCartAfterAddons({
      product: cakeSet,
      selectedVariant,
      multiOrder: true,
      noteContent: `Mooncake Card Add: ${selectedVariant.sku} of ${cakeSet.description}`,
    })
  }
  return (
    <div
      css={[
        tw`relative flex gap-2 md:gap-4 mx-auto w-full`,
        under4 ? tw`flex-col sm:w-4/5` : tw`flex-row sm:w-2/3`,
      ]}
      style={{ flexBasis: '50%' }}
    >
      <PriceTag
        price={selectedVariant.salePrice || selectedVariant.normalPrice}
        under4={under4}
      />
      <OrderButton
        content={'Thêm vào giỏ'}
        handleOnClick={handleAddToCart}
        under4={under4}
      />
    </div>
  )
}

const textStyles = tw`text-center font-barlow-sans-serif font-bold text-base md:text-xl lg:text-2xl`
function PriceTag({ price, under4 }) {
  return (
    <div tw="relative" css={[!under4 ? tw`w-1/2` : '']}>
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-btt/mooncake-group/priceBg.png"
        alt="price tag"
      />
      <p
        tw="w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-sc_red-100"
        css={[textStyles]}
      >
        Giá bán: {currencyFormatter.format(price)}
      </p>
    </div>
  )
}

function OrderButton({ content, handleOnClick, under4 }) {
  return (
    <motion.button
      variants={buttonVariants}
      whileHover="hover"
      tw="rounded-lg sm:p-2 hover:bg-savor-cake-secondary-blueberry-0
    text-savor-cake-primary-beige-200
    !bg-savor-cake-primary-green-avocado-200"
      css={[textStyles, !under4 ? tw`w-1/2` : '']}
      onClick={handleOnClick}
    >
      {content}
    </motion.button>
  )
}
